<template>
  <div class="solutions">
    <solutions-component :solution-data="data" :solution-menu="otherSolutions"/>
  </div>
</template>

<script>
// import SolutionConfig from '@/utils/solutions-config.js'
import SolutionsComponent from '@/components/Solutions/Index'
import axios from 'axios'
export default {
  name: 'Solutions',
  metaInfo: {
    title: 'Soluções - Ucall'
  },
  components: {
    'solutions-component': SolutionsComponent
  },
  data () {
    return {
      data: {},
      dataList: []
    }
  },
  async mounted () {
    this.solution = this.$route.params.solution
    await this.getByName()
    await this.get()
  },
  watch: {
    $route (to, from) {
      this.solution = to.params.solution
      this.getByName()
    },
    '$i18n.locale': {
      handler: async function (after, before) {
        await this.getByName(this.$route.params.solution)
        await this.get()
      },
      deep: true
    }
  },
  methods: {
    async getByName () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/solutions`, {
          params: {
            $limit: 1,
            $filter: `data/name/iv eq '${this.solution}'`
          },
          headers: {
            'X-Resolve-Urls': 'logo,image',
            'X-Languages': this.$i18n.locale,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          this.data = { ...{ id: resp.data.items[0].id }, ...resp.data.items[0].data }
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.log(error)
          /* eslint-enable no-console */
        })
    },
    async get () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/solutions`, {
          params: {
            $orderby: 'created desc',
            $limit: 8
          },
          headers: {
            'X-Resolve-Urls': 'logo,image',
            'X-Languages': this.$i18n.locale,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          let data = []
          resp.data.items.forEach((element) => {
            data.push({ ...{ id: element.id }, ...element.data })
          })
          this.dataList = data
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.log(error)
          /* eslint-enable no-console */
        })
    }
  },
  computed: {
    solutionDetail () {
      const { solution } = this.$route.params
      return this.dataList.filter((item) => item.name === solution)[0]
    },
    otherSolutions () {
      const { solution } = this.$route.params
      return this.dataList.filter((item) => item.name !== solution)
    }
  }
}
</script>

<style lang="scss" scoped>
.solutions {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
