<template>
  <main>
    <div class="solution-detail">
      <solutions-wrapper2
        @close-solution-detail="closeSolutionDetail"
        :solution-data="solutionData"
      />
    </div>
    <div
      class="bg"
      :style="{ backgroundImage: `url(${solutionData.image})` }"
    />
    <div class="list-solutions">
      <solutions-wrapper1
        @close-solution-list="closeSolutionList"
        :solution-data="solutionData"
        :solution-menu="solutionMenu"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import TweenLite from 'gsap/TweenLite'
import SolutionListWrapper from './SolutionListWrapper'
import SolutionDescriptionWrapper from './SolutionDescriptionWrapper'
let flexData

export default {
  name: 'Solutions',
  components: {
    'solutions-wrapper1': SolutionListWrapper,
    'solutions-wrapper2': SolutionDescriptionWrapper
  },
  props: {
    solutionData: {
      type: Object,
      required: true
    },
    solutionMenu: {
      type: Array,
      required: true
    }
  },
  mounted () {
    if (window.screen.width > 768) {
      flexData = 0.4
    } else {
      flexData = 4
    }
    TweenLite.to('.list-solutions', 1, { flex: flexData })
  },
  computed: {
    ...mapGetters('solutions', ['currentSettedBackgrond'])
  },
  methods: {
    closeSolutionList () {
      if (window.screen.width > 768) {
        flexData = { flex: 1 }
      } else {
        flexData = { flex: 2 }
      }
      TweenLite.to('.list-solutions', 0.4, { flex: 0 })
      TweenLite.to('.solution-detail', 0.4, { ...flexData, filter: 'blur(0px)' })
      document.querySelector('.solution-detail').scrollTo(0, 0)
    },
    closeSolutionDetail () {
      if (window.screen.width > 768) {
        flexData = 0.4
      } else {
        flexData = 4
      }

      TweenLite.to('.solution-detail', 0.4, { filter: 'blur(1px)', flex: 0 })
      TweenLite.to('.list-solutions', 0.4, { flex: flexData })
    }
  }
}
</script>
<style scoped>
.bg {
  flex: 1;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  transition: background 0.26s ease-in;
}

main {
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
}

.list-solutions {
  flex: 0; /* flex: .4; */
  overflow: hidden;
}

.solution-detail {
  flex: 0; /* flex: .4; */
  overflow: hidden;
  filter: blur(2px);
}

@media (min-width: 768px) {
  main {
    flex-direction: row-reverse;
  }
}
</style>
