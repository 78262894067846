<template>
  <button @click="changeState">
    <!-- <img src="@/assets/plu.png" alt="" srcset="" /> -->
    <span class="plus icon-plus" :class="intl"></span>
    <span class="btn-see-more">{{ $t("app.readMore") }}</span>
  </button>
</template>

<script>
export default {
  name: 'ReadMore',
  props: {
    changeState: {
      required: true
    },
    intl: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
button:hover {
  cursor: pointer;
}
button {
  margin: 25px 0;
  border: 1px solid #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px;
  color: #fff;
  width: 50%;
  outline: none;
  .plus {
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    font-size: 7pt;
    background: #fff;
    border-radius: 50%;
    margin-right: 4px;
  }

  span {
    font-size: 14px;
    // padding:0 0 13px 5px;
  }
  .btn-see-more {
    padding-left: 20px;
  }
  @media(min-width:768px){
      min-width: 104px!important;
  }
}
</style>
